* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #131624;
}

@mixin font-face($font, $file) {
  $path: './components/atoms/fonts/'+ $file;

  @font-face {
      font-family: '#{$font}';
      src: url($path + '.ttf');
  }
}

@include font-face('Gilroy-Black', 'Gilroy-Black');