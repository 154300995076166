@keyframes float {
	0% {
		transform:  translateY(17px);
	}
    50% {
		transform: translateY(0px);
	}
	100% {
		transform:  translateY(17px);
	}
}

.coming-soon {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 1.5rem;

	// Media queries
	@media(min-width: 768px) {
		padding: 2.5rem;
	}

	// Media queries
	@media(min-width: 1024px) {
		padding: 4rem;
		justify-content: flex-end;
	}

	&__text {
		color: #F5F5F5;
		font-family: Gilroy-Black;
		font-size: 1.5rem;
		line-height: 1.5rem;
		.primary-color {
			color: #E2E52A;
			display: block;
		}

		// Media queries
		@media(min-width: 768px) {
			font-size: 3.15rem;
			line-height: 3.15rem;
			z-index: 9;
		}

		// Media queries
		@media(min-width: 1024px) {
			font-size: 6rem;
			line-height: 6rem;
		}
	}
}

.astronaut {
    display: block;
    width: 90%;
    margin: 0 auto;
	margin-top: 4rem;
    mix-blend-mode: luminosity;
    animation: 8s infinite ease-in-out float;

	// Media queries
	@media(min-width: 768px) {
		max-width: 620px;
	}

	// Media queries
	@media(min-width: 1024px) {
		position: absolute;
		width: 40%;
		max-width: 3000px;
		top: 0;
		right: 0;
	}
}
